import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const stockAroundList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'druglot/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('Get stock around list err : ', err)

      return {}
    })

  return response
}

const lotAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'druglot',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add lot err : ', err)

      return {}
    })

  return response
}
const lotStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'druglot/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update status lot err : ', err)

      return {}
    })

  return response
}

export default { stockAroundList, lotAdd, lotStatusUpdate }
