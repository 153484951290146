<template>
  <div>
    <v-dialog
      v-model="isAddNewLot"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_lot') }}
        </v-card-title>
        <v-form
          ref="formAddNewLot"
          @submit.prevent="createLot"
        >
          <v-card-text>
            <v-text-field
              v-model="shopName"
              outlined
              dense
              readonly
              :label="$t('clinic_name')"
            ></v-text-field>
            <v-dialog
              ref="dataSelection"
              v-model.trim="menu1"
              :return-value.sync="druglot_date"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="druglot_date"
                  v-bind="attrs"
                  outlined
                  dense
                  readonly
                  :rules="[required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model.trim="druglot_date"
                class=""
                :locale="$i18n.locale"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dataSelection.save(druglot_date)"
                >
                  {{ $t("confirm") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="menu1 = false"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-lot', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import stockAround from '@/api/DrugManagement/stockAround'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isAddNewLot',
    event: 'update:is-add-new-lot',
  },
  props: {
    isAddNewLot: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const shopName = localStorage.getItem('shop_name')
    const moment = require('moment')
    const formAddNewLot = ref(null)
    const druglot_date = ref(moment(new Date()).format('YYYY-MM-DD'))
    const loading = ref(false)
    const menu1 = ref(false)

    const { lotAdd } = stockAround

    const createLot = () => {
      const isFormValid = formAddNewLot.value.validate()
      if (!isFormValid) return
      loading.value = true
      lotAdd({
        druglot_date: druglot_date.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-add-new-lot', false)
        emit('onAdd')
      })
    }

    watch(() => props.isAddNewLot, val => {
      if (val) {
        druglot_date.value = moment(new Date()).format('YYYY-MM-DD')
      }
    })

    return {
      menu1,
      formAddNewLot,
      druglot_date,
      shopName,
      loading,
      createLot,
      required,
    }
  },

}
</script>
