<template>
  <div>
    <AddNewLot
      v-model="isAddNewLot"
      @onAdd="fetchDataTable('add')"
    />
    <v-card>
      <v-card-title class="px-2">
        {{ $t('lot') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewLot = true"
        >
          <v-icon
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon><span> {{ $t('add_lot') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          fab
          outlined
          icon
          class="d-block d-md-none"
          @click="isAddNewLot = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="druglot_status_id"
            :placeholder="$t('status')"
            :label="$t('status')"
            :items="statusList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        hide-default-footer
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template #[`item.druglot_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template #[`item.druglot_status_id`]="{ item }">
          <StatusBlock :status="item.druglot_status_id" />
        </template>
        <template #[`item.druglot_date`]="{ item }">
          <ConverseDate :date="item.druglot_date" />
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                :disabled="item.druglot_active_id == '2'"
                v-bind="attrs"
                @click="druglot_id =item.druglot_id; druglot_status_id_update = item.druglot_status_id;isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.druglot_status_id ==1?icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.druglot_status_id ==1? $t('suspend'):$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="isUpdateStatus"
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ druglot_status_id_update==1?$t('confirmLotSuspend'):$t('cancelLotSuspend') }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processLoading"
            :disabled="processLoading"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiPlus, mdiCheck, mdiDeleteOutline, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useStockAround from './useStockAround'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import AddNewLot from './AddNewLot.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    StatusBlock,
    ConverseDate,
    AddNewLot,
    Pagination,
  },

  setup() {
    const isAddNewLot = ref(false)

    return {
      isAddNewLot,
      ...useStockAround(),
      icons: {
        mdiPlus, mdiCheck, mdiDeleteOutline, mdiAlertOutline,
      },
    }
  },

}
</script>
