import basicStatus from '@/@fake-db/data/basicStatus.json'
import stockAround from '@/api/DrugManagement/stockAround'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useStockAround() {
  const loading = ref(false)
  const searchtext = ref('')
  const druglot_status_id = ref(1)
  const statusList = ref(basicStatus.data)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'druglot_id',
      width: 30,
    },
    {
      text: i18n.t('lot'),
      value: 'druglot_name',
      width: 120,
    },
    {
      text: i18n.t('date'),
      value: 'druglot_date',
      width: 120,
    },
    {
      text: i18n.t('status'),
      align: 'center',
      value: 'druglot_status_id',
      width: 120,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 120,
      sortable: false,
      align: 'center',
    },
  ])
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)

  const isUpdateStatus = ref(false)
  const druglot_id = ref('')
  const druglot_status_id_update = ref('')
  const processLoading = ref(false)

  const { stockAroundList, lotStatusUpdate } = stockAround
  onMounted(() => {
    fetchDataTable()
  })

  const updateStatus = () => {
    processLoading.value = true
    lotStatusUpdate({
      druglot_id: druglot_id.value,
      druglot_status_id: druglot_status_id_update.value == '1' ? '2' : '1',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processLoading.value = false
      isUpdateStatus.value = false
      fetchDataTable('delete')
    })
  }

  const fetchDataTable = event => {
    loading.value = true
    stockAroundList({
      searchtext: searchtext.value,
      druglot_status_id: druglot_status_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalPage.value = count_of_page
      totalDataTableList.value = count
      segmentId.value = segment
      if (event == 'add') {
        options.value.page = 1
      } else if (event == 'delete') {
        if (+options.value.page >= +count_of_page) {
          options.value.page = count_of_page
        }
      }
      loading.value = false
    })
  }

  watch([searchtext, druglot_status_id, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isUpdateStatus,
    processLoading,
    druglot_id,
    druglot_status_id_update,
    updateStatus,
    totalPage,
    loading,
    searchtext,
    druglot_status_id,
    statusList,
    options,
    footer,
    columns,
    dataTableList,
    totalDataTableList,
    segmentId,
    fetchDataTable,
  }
}
